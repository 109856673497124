// 커링, 모듈 패턴 적용
export const dataSource = (storage: any) => {
  return (function (storage: any) {
    const source = storage;

    return {
      get: async (name: string) => {
        return await source.getItem(name);
      },
      set: async (name: string, value: string) => {
        await source.setItem(name, value);
      },
      remove: async (name: string) => {
        await source.removeItem(name);
      },
    };
  })(storage);
};