/*
@Headers("accept: application/json", "Content-Type: application/json")
@POST("/api/note/v1/notes")
fun createNote(@Header("Authorization") token: String, @Body params: CreateNoteData): Call<CreateNoteOkResponse>

@Multipart
@POST("/api/note/v1/resource")
fun uploadNote(@Header("Authorization") token: String, @PartMap params: HashMap<String, RequestBody>, @Part files: List<MultipartBody.Part>): Call<NoteResourceOkResponse>

@GET("/api/note/v1/notes")
fun getNoteResource(@Header("Authorization") token: String, @QueryMap options: HashMap<String, Any>): Call<NoteLoadOkResponse>
*/

// 노트 생성
export function createNote({ aToken } : { 
    aToken: string
}) {
    return {
      method: "POST",
      url: "api/note/v1/notes",
      headers: {
        Authorization: "Bearer " + aToken,
        "Content-Type": "application/json; charset=UTF-8",
      }
    };
}

export function uploadNote({ aToken, id, imageFile } : {
    aToken: string,
    id: string,
    imageFile: File
}) {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("resource", imageFile);
    formData.append("resource_type", "local");

    return {
        method: "POST",
        url: "api/note/v1/resource",
        headers: {
            Authorization: "Bearer " + aToken,
            Accept: 'application/json',
        },
        body: formData 
    }
}

export function getNoteResource({ aToken, offset, limit} : {
    aToken: string,
    offset: number,
    limit: number,

}) {
    const params = { 
        "offset": offset, 
        "limit": limit,
        "creation_time_order_by_asc": false,
        "check_img": true 
    };

    return {
        method: "GET",
        url: "api/note/v1/notes",
        headers: {
            Authorization: "Bearer " + aToken,
            "Content-Type": "application/json; charset=UTF-8",
          },
        params: params
    }
}

export function deleteNoteResource({ aToken, id } : {
    aToken: string,
    id: string,
}) {
    return {
        method: "DELETE",
        url: "api/note/v1/notes",
        headers: {
            Authorization: "Bearer " + aToken,
            "Content-Type": "application/json; charset=UTF-8",
        },
        body: {
            id: id
        }
    }
}