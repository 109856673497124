import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { NoteAPI, NoteStorage } from "../App";
import { uLoginUser } from "../server/User";

const ShowWrapper = styled.div`
    width: 450px;
    height: 860px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: -webkit-center;
    text-align: center;
    background-color: #FCFCFC;
`

const LogoWrapper = styled.img`
    margin-top: 254px;
`

const BlockWrapper = styled.button`
    width: 187px; 
    height: 212px;
    margin: 20px 10px;
    border-radius: 20px;
    border: 0px;
    position: relative;

    :after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 187px; 
        height: 212px;
        background: #FCFCFC;
        border-radius: 20px;
        transition: all 0.8s
    }

    :active:after {
        padding: 0;
        margin: 0;
        opacity: 1;
        transition: 0s
    }

    > img {
        width: 185px; 
        height: 210px;
        border-radius: 20px;
        object-fit: none; 
        object-position: -22px -18px; 
        overflow: visible;
        cursor: pointer;
    }
`

export const NoteShow = () => {
    const navigate = useNavigate();
    const effectCalled = useRef(false);

    useEffect(() => {

        const fetchuser = async () => {
            const id = "member2";
            const password = "member2";
            try {
                const response = await NoteAPI(uLoginUser({ id, password }));
                if (response.status == 200 || response.status == 201) {
                    NoteStorage.set("UserAuth", response.data.access_token);
                    return;
                }
            } catch (err) {
                console.log(err);
            }
            return;
        }

        if (!effectCalled.current) {
            fetchuser();
        }
        // fetchuser();
        effectCalled.current = true;
    }, []);

    const onShowTemple = () => {
        navigate('temple')
    }

    const onShowNote = () => {
        navigate('search')
    }

    return (
        <ShowWrapper>
            <LogoWrapper src={require("../assets/logo.png")} />
            <div>
                <BlockWrapper onClick={onShowTemple}>
                    <img src={require("../assets/block_1.png")}/>
                </BlockWrapper>
                <BlockWrapper onClick={onShowNote}>
                    <img src={require("../assets/block_2.png")}/>
                </BlockWrapper>
            </div>
        </ShowWrapper>
    )
}