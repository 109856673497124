import React, { useCallback, useRef, useState }  from "react";
import styled from "styled-components";
import { DrawStage } from "../draw/DrawStage";
import { DrawAssets } from "../assets";
import  BottomTool  from '../tools/BottomTool';
import { useLocation, useNavigate } from "react-router-dom";
import { NoteEndDialog, NoteSaveDialog } from "./NoteDialog";
import { Stage } from "konva/lib/Stage";
import { NoteAPI, NoteStorage } from "../App";
import { Note } from "../server"

const AppContainer = styled.div`
  width: 100%;
  height: 100%;
`

const WriteContainer = styled.div`
  height: 100%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);

    >.optionTool {
        position: absolute;
    }
`;

const ToolContainer = styled.div`
    bottom: 0;
    left: 50%;
    transform-origin: left bottom;
    transform: translate(-50%, 0%);
    width: 570px;
    height: 172px; 
    position: relative;
    background-color: #EAEFF8;
    border-top-left-radius: 30px 30px;
    border-top-right-radius: 30px 30px;
    overflow: hidden;

    > div {
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        background-color: #EAEFF8;
    }
`

const WeightWrapper = styled.div`
    justify-content: center;
    margin-top: 64px;
    > button {
        cursor: pointer;
        border: 0px;
        background-color: transparent;
        margin: 0px 9px 0px 9px;
        width: 52px;
        height: 52px;
    }
`

const ColorWrapper = styled.div`
    justify-content: center;
    margin-top: 60px;
    > button {
        cursor: pointer;
        border: 0px;
        background-color: transparent;
        margin: 0px 5px 0px 5px;
        width: 60px;
        height: 60px;
    }
`

const FitImgWrapper = styled.img`
    width:100%;
    height:100%;
`
const SizeContainer = React.memo(function getSizeView({ size, onSizeChange } : 
    { toolSelected: number, size: number, onSizeChange: (size: number) => void }) {
    return (
      <WeightWrapper>
        {
            DrawAssets.size.map((img, index) => { 
              return (
                <button key={index} onClick={()=>{onSizeChange(index)}}>
                    { 
                        size == index ? 
                        <FitImgWrapper src={ require(`../assets/${img.selected}`) } /> 
                        : <FitImgWrapper src={ require(`../assets/${img.default}`) } /> 
                    }
                </button>
              )
            })
        }
      </WeightWrapper>
    )
  });
  
  const ColorContainer = React.memo(function getColorView({ color, onColorChange } : 
      { toolSelected: number, color: number, onColorChange: (color: number) => void }) {
    return(
      <ColorWrapper>
          {
            DrawAssets.color.map((img, index) => {
                return (
                <button key={index} onClick={() => onColorChange(index)}>
                    { 
                        color == index ? 
                        <FitImgWrapper src={ require(`../assets/${img.selected}`) }/> 
                        :  <FitImgWrapper src={ require(`../assets/${img.default}`) }/> 
                    }
                </button>
                )
            })
        }
      </ColorWrapper>
    )
  })
  
  const initDrawData = {
    dialogMode: "tool", // tool, size, color
    toolSelected: 0,
    size: 2,
    color: 5,
    sticks: [
      {
        size: 2,
        color: 5
      },
      {
        size: 2,
        color: 5
     }
    ],
    src: "temple_white.png"
  }

export const NoteWrite = ({toolScale, screenWidth, screenHeight}
  : { toolScale: number,
      screenWidth: number,
      screenHeight: number}) => {
    const navigate = useNavigate();
    const [isSaveDialog, setSaveDialog] = useState(false);
    const [isEndDialog, setEndDialog] = useState(false);
    const [drawDatas, setDrawData] = useState(initDrawData);
    const stageRef = useRef<Stage>(null);
    const location = useLocation();

    const imgName = location.state.imgName;

    const onToolChange = useCallback((index: number) => {
       setDrawData({
          ...drawDatas,
          toolSelected: index,
          size: index != 2 ? drawDatas.sticks[index].size : drawDatas.size,
          color: index != 2 ? drawDatas.sticks[index].color : drawDatas.color
       })
    }, [drawDatas])
  
    const onColorChange = useCallback((color: number) => {
      setDrawData({
        ...drawDatas,
        dialogMode: "tool",
        color: color,
        sticks: drawDatas.sticks.map((stick, index) => 
            drawDatas.toolSelected == index ? { ...stick, color: color }  : stick  
          )
      })
    }, [drawDatas.toolSelected, drawDatas.color, drawDatas.size])
  
    const onSizeChange = useCallback((size: number) => {
      setDrawData({
        ...drawDatas,
        dialogMode: "tool",
        size: size,
        sticks: drawDatas.sticks.map((stick, index) => 
            drawDatas.toolSelected == index ? { ...stick, size: size }  : stick  
          )
      })
    }, [drawDatas.toolSelected, drawDatas.size, drawDatas.color])
  
    const onShowColor = useCallback(() => {
      setDrawData({
        ...drawDatas,
        dialogMode: "color"
      })
    }, [drawDatas.toolSelected, drawDatas.color, drawDatas.size])
  
    const onShowSize = useCallback(() => {
      setDrawData({
        ...drawDatas,
        dialogMode: "size"
      })
    }, [drawDatas.toolSelected, drawDatas.size, drawDatas.color])

    const onSaveShow = useCallback(() => {
      setSaveDialog(true);
    },[])

    const onEndShow = useCallback(() => {
      setEndDialog(true);
    }, [])

    const onSaveClick = async () => {
      const uri = stageRef.current?.toDataURL();
      if (uri != null) {
        try {
          const file = drawUrlToFile(uri);
          const aToken = await NoteStorage.get("UserAuth");
  
          const createResponse = await NoteAPI(Note.createNote({aToken: aToken}));
          if (createResponse.status == 200 || createResponse.status == 201) {
            const uploadResponse = await NoteAPI(Note.uploadNote({ aToken: aToken, id: createResponse.data.result.id, imageFile: file }));
            if (uploadResponse.status == 200 || uploadResponse.status == 201) {
              navigate("/temple")
            }
          }
        } catch (err) {
          console.log(err);
        }
      }
      return;
    }

    const onCancelClick = () => {
      setSaveDialog(false);
      setEndDialog(false);
      return;
    }

    const onCloseClick = () => {
        navigate('/temple');
    }

    return (
      <AppContainer>
        <WriteContainer style={{ width: `${screenWidth}px`, height: `${screenHeight}px`}}>
            <DrawStage drawDatas={drawDatas} stageRef={stageRef} screenWidth={screenWidth} screenHeight={screenHeight} imgName={imgName}/>
            <ToolContainer className="optionTool" style={{ scale: `${toolScale}` }}>
            { (drawDatas.dialogMode === "tool") 
                && <BottomTool 
                    drawDatas={drawDatas} 
                    onToolChange={onToolChange} 
                    onShowColor={onShowColor}
                    onShowSize={onShowSize}
                    onSaveShow={onSaveShow}
                    onEndShow={onEndShow}
                    />}
            { drawDatas.dialogMode === "size" && <SizeContainer toolSelected={drawDatas.toolSelected} size={drawDatas.size} onSizeChange={onSizeChange}/>}
            { drawDatas.dialogMode === "color" && <ColorContainer toolSelected={drawDatas.toolSelected} color={drawDatas.color} onColorChange={onColorChange}/>}
            </ToolContainer>
        </WriteContainer>
        { isSaveDialog && <NoteSaveDialog onSuccess={onSaveClick} onCancel={onCancelClick}/> }
        { isEndDialog && <NoteEndDialog onSuccess={onSaveClick} onCancel={onCancelClick} onClose={onCloseClick}/> }
      </AppContainer>
    )
}

function downloadURI(uri: string, name: string) {
  const link = document.createElement('a');
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function drawUrlToFile(dataUrl: string) {
  const byteString = window.atob(dataUrl.split(',')[1]);
  const array: number[] = [];

  for (let i = 0; i < byteString.length; i++) {
    array.push(byteString.charCodeAt(i));
  }

  const myBlob = new Blob([new Uint8Array(array)], {type: "image/png"});
  const file = new File([myBlob], "blobtofile.png", { type: "image/png" });
  return file;
}