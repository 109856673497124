// 로그인
export function uLoginUser({ id, password }: { id: string; password: string }) {
    return {
      method: "POST",
      url: "svc/user/issue-auth-token/by-password",
      body: {
        auth_name: id,
        auth_password: window.btoa(password),
        enc_type: "base64"
      },
    };
}