import axios, { AxiosHeaders, AxiosRequestConfig, AxiosResponse } from "axios";

export interface IRequestOption {
    readonly method: string
    readonly url: string
    readonly headers?: any
    readonly body?: any
    readonly params?: any
}

export type IRequest = (requestOption: IRequestOption) => Promise<{
    status: number,
    data: any,
}>

// Fetch, Axios 상관 없이 같은 결과를 보낼 수 있는 방법?? 
// Return Data Model로 통일이 필요 
// Success or Error 

export const requestAxios = (baseUrl: string) => async (requestOption: IRequestOption) => {
    const requestUrl = `${baseUrl}/${requestOption.url}`;
    const option: AxiosRequestConfig = { method: requestOption.method, url: requestUrl };
    
    if(requestOption?.headers) { 
        option.headers = new AxiosHeaders(requestOption.headers);
    }

    if(requestOption?.body != null) { 
        option.data = requestOption.body; 
    }

    if (requestOption?.params != null) {
        option.params = requestOption.params;
    }

    const response = await axios(option);

    return {
        status: response.status,
        data: response.data,
    };
}


const requestFetch = (baseUrl: string) => async(requestOption: IRequestOption) => {
    const requestUrl = `${baseUrl}/${requestOption.url}`;
    const option: RequestInit = { method: requestOption.method };

    if(requestOption?.headers) { 
        option.headers = new Headers(requestOption.headers);
    }

    if(requestOption?.body) {
        option.body = JSON.stringify(requestOption.body);
    }

    try {
      const res = await fetch(requestUrl, option);
      const data = await res.json()
      return {
        status: res.status,
        data: data
      }
    } catch (e) {
      return {
        status: 500,
        data: {}
      }
    }
}
