import React from "react";

export function TempTitleCard({
    selected,
    onClick,
    title
  }: {
    selected: boolean,
    onClick: () => void,
    title: string;
  }) {
    return (
        <div 
            className="card"
            tabIndex={0}
            role="button" 
            onClick={() => onClick()} 
            style={{ 
                border: "0",
                borderRadius: "8px",
                display: "inline-block",
                margin: "0 6px",
                padding: "8px 12px",
                height: "24px",
                minWidth: "60px",
                whiteSpace: "nowrap",
                userSelect: "none",
                backgroundColor: selected ? "#F64C4C" : "#EAEFF8",
            }}>
            <div style={{ color: selected ? "white": "black", fontSize: "13px", display: "inline-block"}}>{title}</div>
        </div>
    );
}


// 3:4 
export function TempNoteCard({
    cardName,
    selected,
    onClick
}: {
    cardName: string,
    selected: boolean,
    onClick: () => void
}) {

    return (
        <div 
            className="card"
            tabIndex={0}
            role="button" 
            onClick={() => onClick()} 
            style={{ 
                border: "0",
                borderRadius: "8px",
                display: "inline-block",
                padding: "4px 0px",
                whiteSpace: "nowrap",
                userSelect: "none",
                margin: "0 8px",
            }}>
        <img
            style={{
            backgroundColor: "bisque",
            width: "150px",
            height: "200px",
            border: selected ? "3px solid" : "1px solid",
            borderColor: selected ? "#F64C4C" : "transparent"
            }}
            src={require("../assets/" + cardName)}
        />
    </div>
    )
}