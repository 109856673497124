import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AnimateView } from './AnimateView';
import { NoteSearch } from './search/NoteSearch';
import { requestAxios } from './server/NetworkService';
import { dataSource } from './server/Storage';
import { NoteShow } from './show/show';
import { NoteTemple } from './temple/NoteTemple';
import { ScrollText } from './temple/ScrollTest';
import { NoteWrite } from './write/NoteWrite';
import { NoteView } from './note/NoteView';

export const NoteAPI = requestAxios("https://mate2.dev.meetmate.co.kr");
export const NoteStorage = dataSource(localStorage);
 
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<NoteShow/>} />
        <Route path='/temple' element={<NoteTemple/>} />
        <Route path='/search' element={<NoteSearch/>} />
        <Route path="/write" element={<NoteWrite toolScale={0.6} screenWidth={450} screenHeight={860}/>} />
        <Route path='/animate' element={<AnimateView />} />
        <Route path='/scroll' element={<ScrollText />} />
        <Route path='/note' element={<NoteView />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
