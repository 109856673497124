import React, { useRef, useState } from "react";
import { Stage, Layer, Line, Image, Transformer } from "react-konva";
import { useImage } from "react-konva-utils";

const toolArray = ["pen", "maker", "eraser"];
const colorArray = [
  "#d40000",
  "#f77b18",
  "#f0af22",
  "#64C125",
  "#1258dc",
  "#000000",
];
const sizeArray = [4, 5, 8, 10, 14, 17];
const opacityArray = [1, 0.3, 1];

type BottomToolProps = {
  drawDatas: {
    dialogMode: string; // tool, size, color
    toolSelected: number; // pen, marker, eraser
    color: number;
    size: number;
    sticks: {
      size: number;
      color: number;
    }[];
    src: string;
  };
  screenWidth: number;
  screenHeight: number;
  imgName: string;
  stageRef: any;
};

type lines = {
  tool: string;
  strokeWidth: number;
  opacity: number;
  color: string;
  points: number[];
}[];

export const DrawStage = (props: BottomToolProps) => {
  const [lines, setLines] = useState<lines>([]);
  const isDrawing = useRef(false);

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const url = require("../assets/" + props.imgName);
  const [image, status] = useImage(url);

  const tool = toolArray[props.drawDatas.toolSelected];
  const color = colorArray[props.drawDatas.color];
  const opacity = opacityArray[props.drawDatas.toolSelected];
  const strokeWidth = sizeArray[props.drawDatas.size];

  const handleMouseDown = (e: any) => {
    isDrawing.current = true;
    const pos = e.target.getStage().getPointerPosition();
    setLines([
      ...lines,
      { tool, strokeWidth, opacity, color, points: [pos.x, pos.y] },
    ]);
  };

  const handleMouseMove = (e: any) => {
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    const lastLine = lines[lines.length - 1];

    // add point
    lastLine.points = lastLine.points.concat([point.x, point.y]);

    // replace last
    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
  };

  return (
    <div style={{ height: props.screenHeight, overflow: "overlay" }}>
      <Stage
        ref={props.stageRef}
        width={props.screenWidth}
        height={props.screenHeight}
        onMouseDown={handleMouseDown}
        onMousemove={handleMouseMove}
        onMouseup={handleMouseUp}
        onTouchStart={handleMouseDown}
        onTouchMove={handleMouseMove}
        onTouchEnd={handleMouseUp}
      >
        <Layer>
          <Image
            image={image}
            width={props.screenWidth}
            height={props.screenHeight}
          />
        </Layer>
        <Layer>
          {lines.map((line, i) => (
            <Line
              key={i}
              points={line.points}
              stroke={line.color}
              strokeWidth={line.strokeWidth}
              tension={0.5}
              lineCap="round"
              lineJoin="round"
              opacity={line.opacity}
              globalCompositeOperation={
                line.tool === "eraser" ? "destination-out" : "source-over"
              }
            />
          ))}
        </Layer>
      </Stage>
    </div>
  );
};
