import React from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import 'react-horizontal-scrolling-menu/dist/styles.css';
import "./hideScrollbar.css";

function Arrow({
    children,
    disabled,
    onClick
  }: {
    children: React.ReactNode;
    disabled: boolean;
    onClick: VoidFunction;
  }) {
    return (
      <button
        disabled={disabled}
        onClick={onClick}
        style={{
          cursor: "pointer",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          right: "1%",
          opacity: disabled ? "0" : "1",
          userSelect: "none"
        }}
      >
        {children}
      </button>
    );
}
  
function LeftArrow() {
    const {
      isFirstItemVisible,
      scrollPrev,
      visibleElements,
      initComplete
    } = React.useContext(VisibilityContext);
  
    const [disabled, setDisabled] = React.useState(
      !initComplete || (initComplete && isFirstItemVisible)
    );
    React.useEffect(() => {
      // NOTE: detect if whole component visible
      if (visibleElements.length) {
        setDisabled(isFirstItemVisible);
      }
    }, [isFirstItemVisible, visibleElements]);
  
    return (
      <Arrow disabled={disabled} onClick={() => scrollPrev()}>
        Left
      </Arrow>
    );
}
  
function RightArrow() {
    const { isLastItemVisible, scrollNext, visibleElements } = React.useContext(
      VisibilityContext
    );
  
    // console.log({ isLastItemVisible });
    const [disabled, setDisabled] = React.useState(
      !visibleElements.length && isLastItemVisible
    );
    React.useEffect(() => {
      if (visibleElements.length) {
        setDisabled(isLastItemVisible);
      }
    }, [isLastItemVisible, visibleElements]);
  
    return (
      <Arrow disabled={disabled} onClick={() => scrollNext()}>
        Right
      </Arrow>
    );
}
  
function Card({ title, itemId }: { title: string; itemId: string }) {
    const visibility = React.useContext(VisibilityContext);
  
    const visible = visibility.isItemVisible(itemId);
  
    return (
      <div
        role="button"
        style={{
          border: "1px solid",
          display: "inline-block",
          margin: "0 10px",
          width: "160px",
          userSelect: "none"
        }}
        tabIndex={0}
        className="card"
      >
        <div>
          <div>{title}</div>
          <div style={{ backgroundColor: visible ? "transparent" : "gray" }}>
            visible: {JSON.stringify(visible)}
          </div>
        </div>
        <div
          style={{
            backgroundColor: "bisque",
            height: "200px"
          }}
        />
      </div>
    );
}

const preventDefault = (ev: Event) => {
    if (ev.preventDefault) {
      ev.preventDefault();
    }
    ev.returnValue = false;
};
  
const enableBodyScroll = () => {
    document && document.removeEventListener("wheel", preventDefault, false);
};
const disableBodyScroll = () => {
    document &&
        document.addEventListener("wheel", preventDefault, {
            passive: false
        });
};
  
function usePreventBodyScroll() {
    const [hidden, setHidden] = React.useState(false);
  
    React.useEffect(() => {
      hidden ? disableBodyScroll() : enableBodyScroll();
  
      return enableBodyScroll;
    }, [hidden]);
  
    const disableScroll = React.useCallback(() => setHidden(true), []);
    const enableScroll = React.useCallback(() => setHidden(false), []);
    return { disableScroll, enableScroll };
}
  
function onWheel(apiObj: scrollVisibilityApiType, ev: React.WheelEvent): void {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;
  
    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }
  
    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  }

  
  type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

const elemPrefix = "test";
const getId = (index: number) => `${elemPrefix}${index}`;

const getItems = () =>
  Array(20)
    .fill(0)
    .map((_, ind) => ({ id: getId(ind) }));

export function ScrollText() {
  const [items] = React.useState(getItems);
  const { disableScroll, enableScroll } = usePreventBodyScroll();

  return (
    <>
      <div className="example" style={{ paddingTop: "100px" }}>
        <div onMouseEnter={disableScroll} onMouseLeave={enableScroll}>
          <ScrollMenu
            Header={<div>HEADER</div>}
            Footer={<div>FOOTER</div>}
            LeftArrow={LeftArrow}
            RightArrow={RightArrow}
            onWheel={onWheel}
          >
            {items.map(({ id }) => (
              <Card
                title={id}
                itemId={id} // NOTE: itemId is required for track items
                key={id}
              />
            ))}
          </ScrollMenu>
        </div>
      </div>
    </>
  );
}
