import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { NoteAPI, NoteStorage } from "../App";
import { Note } from "../server";
import { useNavigate } from "react-router-dom";

const ShowWrapper = styled.div`
  width: 450px;
  height: 860px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: -webkit-center;
  text-align: center;
  background-color: #27262d;
`;

const TitleWrapper = styled.div`
  display: flex;
  color: white;
  margin: 24px 24px 0 20px;
`;

const TitleText = styled.div`
  margin: auto;
  margin-left: 4px;
`;

const SwitchWrapper = styled.label`
  font-size: 13px;
  font-weight: 500;
  color: white;

  display: inline-block;
  margin: 0px;
  position: relative;

  > label {
    margin: 0px;
    width: 140px;
    height: 30px;
    background: #000000;
    border-radius: 26px;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease;
    /*box-shadow: 0px 0px 8px 0px rgba(17, 17, 17, 0.34) inset;*/
    display: block;
  }

  > label:before {
    content: attr(data-on);
    position: absolute;
    font-size: 12px;
    font-weight: 500;
    top: 7px;
    right: 16px;
    color: #777777;
  }

  > label:after {
    content: attr(data-off);
    width: 70px;
    height: 16px;
    background: #393841;
    border-radius: 26px;
    position: absolute;
    left: 2px;
    top: 2px;
    text-align: center;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 6px -2px #111;
    padding: 5px 0px;
  }

  > input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    z-index: 1;
    margin: 0px;
  }

  > input[type="checkbox"]:checked ~ label {
    color: #fff;
  }

  > input[type="checkbox"]:checked ~ label:after {
    content: attr(data-on);
    left: 68px;
    background: #3c3c3c;
  }

  > input[type="checkbox"]:checked ~ label:before {
    content: attr(data-off);
    right: auto;
    left: 20px;
  }
`;

const ListWrapper = styled.div`
  padding-top: 16px;
  height: 790px;
  overflow: auto;
  margin: 12px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 324px 324px;

  > .item {
    height: 300px;
    width: 208px;
    color: white;
    padding: 12px;
    border: 0;
    background: transparent;

    > img {
      width: 100%;
      height: 268px;
    }

    > h4 {
      text-align: left;
      text-align: -webkit-left;
    }
  }
`;

const HomeWrapper = styled.button`
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 48px;
  height: 48px;
  margin: 24px;
  border: 0;
  border-radius: 24px;
  background-color: #397ee2;

  > img {
    width: 24px;
    height: 24px;
  }
`;

type NoteList = {
  id: string;
  resource: {
    image_url: string;
    audio_urls: string[];
  };
  creation_time: string;
};

function useFetch(page: number) {
  const size = 20;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [list, setList] = useState<NoteList[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const effectCalled = useRef(false);

  const sendQuery = useCallback(async () => {
    try {
      const offset = size * (page - 1);
      setLoading(true);
      setError(false);
      const userAuth = await NoteStorage.get("UserAuth");
      const res = await NoteAPI(
        Note.getNoteResource({ aToken: userAuth, offset: offset, limit: size })
      );
      const items = res.data.result.items;
      setList((prev) => [...new Set([...prev, ...items])]);
      setHasMore(res.data.result.total_count > page * size);
    } catch (err) {
      setError(true);
    }
  }, [page]);

  useEffect(() => {
    if (!effectCalled.current) {
      sendQuery();
    }
    effectCalled.current = true;
  }, [sendQuery, page]);

  return { loading, error, list, hasMore };
}

export const NoteSearch = () => {
  const navigate = useNavigate();
  const [pageNum, setPageNum] = useState(1);
  const { loading, error, list, hasMore } = useFetch(pageNum);

  const observer = useRef<IntersectionObserver>();
  const lastNoteElement = useCallback(
    (node: HTMLButtonElement) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNum((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const baseUrl = "https://mate2.dev.meetmate.co.kr";

  const onItemClick = useCallback(
    (id: string, imageUrl: string) => {
      if (id == null) return;
      navigate("/note", {
        state: {
          id: id,
          imageUrl: imageUrl,
        },
      });
    },
    [loading, hasMore]
  );

  const onShowHome = useCallback(() => {
    navigate("/");
  }, []);

  return (
    <ShowWrapper>
      <TitleWrapper>
        <TitleText>
          <h3>총 {list?.length}개</h3>
        </TitleText>
        <SwitchWrapper className="switch btn-color-mode-switch">
          <input
            type="checkbox"
            name="color_mode"
            id="color_mode"
            value="1"
          ></input>
          <label htmlFor="color_mode" data-on="오래된순" data-off="최신순" />
        </SwitchWrapper>
      </TitleWrapper>
      <ListWrapper>
        {list?.map((item, i) => {
          const createTime = item.creation_time
            ? item.creation_time.slice(0, 10).replaceAll("-", ".")
            : "00.00.00";
          const imageUrl = item.resource
            ? `${baseUrl}${item.resource.image_url}`
            : "";
          const isLastElement = list.length === i + 1;
          return isLastElement ? (
            <button
              className="item"
              key={i}
              ref={lastNoteElement}
              onClick={() => onItemClick(item.id, imageUrl)}
            >
              <img src={imageUrl} />
              <h4>{createTime}</h4>
            </button>
          ) : (
            <button
              className="item"
              key={i}
              onClick={() => onItemClick(item.id, imageUrl)}
            >
              <img src={imageUrl} />
              <h4>{createTime}</h4>
            </button>
          );
        })}
      </ListWrapper>
      <HomeWrapper onClick={onShowHome}>
        <img src={require("../assets/ic_home_white.png")} />
      </HomeWrapper>
    </ShowWrapper>
  );
};
