import React from "react";
import styled from "styled-components";
import { Stage, Layer, Rect, Shape, Circle } from "react-konva";
import { useSpring, animated } from "react-spring";

const AppContainer = styled.div`
  height: 100%;
  position: relative;
    > div{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

export const AnimateView = () => {

    const props = useSpring({
        from: { opacity: 1, transform: "translateX(10%)" },
        to: { opacity: 1, transform: "translateX(0%)" },
    });

    return (
        <AppContainer>
            <div>
                <Stage 
                    style={{background: "#003458"}}
                    width={400}
                    height={400}>
                    <Layer>
                        <Shape
                            sceneFunc={(context, shape) => {
                                context.beginPath();
                                context.moveTo(44,75);
                                context.lineTo(60,110);
                                context.closePath();
                                context.fillStrokeShape(shape);
                            }}
                            strokeWidth={2}
                            stroke={"white"}/>
                        <Shape
                            sceneFunc={(context, shape) => {
                                context.beginPath();
                                context.moveTo(120,75);
                                context.lineTo(104,110);
                                context.closePath();
                                context.fillStrokeShape(shape);
                            }}
                            strokeWidth={2}
                            stroke={"white"}/>
                         <Shape
                            sceneFunc={(context, shape) => {
                                context.beginPath();
                                context.moveTo(300,290);
                                context.lineTo(284,325);
                                context.closePath();
                                context.fillStrokeShape(shape);
                            }}
                            strokeWidth={2}
                            stroke={"white"}/>
                        <Shape
                            sceneFunc={(context, shape) => {
                                context.beginPath();
                                context.moveTo(344,290);
                                context.lineTo(360,325);
                                context.closePath();
                                context.fillStrokeShape(shape);
                            }}
                            strokeWidth={2}
                            stroke={"white"}/>
                        <Rect x={40} y={110} width={320} height={180} stroke={"white"}/>
                        <Shape
                            sceneFunc={(context, shape) => {
                                context.beginPath();
                                context.moveTo(60,110);
                                context.lineTo(104,110);
                                context.closePath();
                                context.fillStrokeShape(shape);
                            }}
                            strokeWidth={2}
                            stroke={"#003458"}/>
                        <Shape
                            sceneFunc={(context, shape) => {
                                context.beginPath();
                                context.moveTo(300,290);
                                context.lineTo(344,290);
                                context.closePath();
                                context.fillStrokeShape(shape);
                            }}
                            strokeWidth={2}
                            stroke={"#003458"}/>

                        {/* 160 200 240 */}
                        <animated.div style={props}>
                            <Circle 
                                width={30} height={30} x={160} y={200} fill={"white"}/>    
                        </animated.div>
                    </Layer>
                </Stage>
            </div>
        </AppContainer>
    )
}