import React, { useCallback } from "react";
import styled from "styled-components";
import { DrawAssets } from "../assets";

// Stack View 만들기 => positon relative & absolute 활용하기
// display none - 조건부 렌더링

// 네이밍 - Style.ToolWrapper
const StickForm = styled.form`
    padding: 0px;
    margin: 30px 45px 0px 45px;
    display: flex;
    width: 164px;

    > div {
        padding: 0px;
        width: 38px;
        overflow: hidden;
    }
`

// 형제 ~, 자식 >
const RadioBox = styled.input`
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;

    & ~ label {
        cursor: pointer;
        width:38px;
        height: 142px;
        transform: translateY(30px);
    }
    &:checked ~ label {
        transform: translateY(0px);
    }
`

const ButtonWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 27px 45px 27px 0px;
    flex: 1;
`
const RecordWrapper = styled.button`
    margin: 0;
    margin-left: 10px;
    width: 192px;
    height: 48px;
    background-color: #DDE4F1;
    border-radius: 40px;
    border-color: transparent;
    display: flex;
    align-items: center;

    > img {
        margin-left: 30px;
        margin-right: 8px;
        width: 16px;
        height: 24px;   
    }

    > b {
        height: 25px;
        margin-left: 0px;
        margin-right: 28px;
        text-align: center;
        font: normal normal bold 22px/30px NanumSquareRoundOTF;
        letter-spacing: -0.66px;
        color: #333333;
    }
`
const CloseWrapper = styled.button`
    width: 48px;
    height: 48px;
    background-color: Transparent;
    border: none;       
    cursor:pointer;
    
    > img {
        width: 18px;
        height: 19px;
    }
`

const TransButton = styled.button`
    margin-top: 14px;
    width: 52px;
    height: 52px;
    background-color: Transparent;
    border: none;       
    cursor:pointer;
`

type BottomToolProps = {
    drawDatas: {
        dialogMode: string, // tool, size, color
        toolSelected: number, // pen, marker, eraser
        color: number,
        size: number,
        sticks: {
              size: number,
              color: number
        }[]
    },
    onToolChange: (index: number) => void,
    onShowColor: () => void,
    onShowSize: () => void,
    onSaveShow: () => void,
    onEndShow: () => void
}


const StickPen = React.memo(function getStickPen(
    {toolSelected, color, onToolChange} : {
        toolSelected: number, 
        color: number,
        onToolChange: (name: number) => void}) {
        return (
            <div style={{ marginRight: "27px" }}>
                <RadioBox 
                    id="pen" 
                    type="radio" 
                    onChange={() => {onToolChange(0)}}
                    checked={toolSelected === 0}
                    />
                <label htmlFor="pen" style={{ content: `url(${require("../assets/" + DrawAssets.pen[color])})` }}>Pen</label>
            </div>
        )
    }
)

const StickMarker = React.memo(function getStickMarker(
    { toolSelected, color, onToolChange } : {
        toolSelected: number, 
        color: number,
        onToolChange: (name: number) => void
    }) {
        return (
            <div style={{ marginRight: "23px" }}>
                <RadioBox 
                    id="marker"
                    type="radio" 
                    onChange={() => {onToolChange(1)}}
                    checked={toolSelected === 1}
                    />
                <label htmlFor="marker" style={{ content: `url(${require("../assets/" + DrawAssets.marker[color])})` }}>Marker</label>
            </div>
        )
    }
)

const StickEraser = React.memo(function getStickEraser(
    { toolSelected, onToolChange } : {
        toolSelected: number,
        onToolChange: (name: number) => void
    }) {
        return (
            <div>
                <RadioBox 
                    id="eraser" 
                    type="radio" 
                    onChange={() => {onToolChange(2)}}
                    checked={toolSelected === 2}
                    />
                <label htmlFor="eraser" style={{ content: `url(${require("../assets/" + DrawAssets.eraser)})` }}>Eraser</label>
            </div>
        )
    }
)

const BottomTool = (props: BottomToolProps) => {
    const SizeButton = useCallback((size: number, onShowSize: () => void) => {
        return (
            <TransButton name="size" onClick={onShowSize}>
                <img src={require("../assets/" + DrawAssets.size[size].selected)} style={{ width: "52px", height: "52px"}}/>
            </TransButton>
        )
    }, [props.drawDatas.toolSelected])


    const ColorButton = useCallback((color: number, onShowColor: () => void) => {
        return (
            <TransButton name="color" onClick={onShowColor}>
                  <img src={require("../assets/" + DrawAssets.color[color].selected)} style={{ width: "52px", height: "52px"}}/>
            </TransButton>
        )
    }, [props.drawDatas.toolSelected])

    const RecordButton = React.memo(function recordButton() {
        return (
            <RecordWrapper style={{visibility: "hidden"}}>
                <img src={require("../assets/ic_record_round.png")} />
                <b>녹음</b>
                <b>0/5</b>
            </RecordWrapper>
        )
    })

    const CloseButton = React.memo(function closeButton() {
        return (
            <CloseWrapper style={{visibility: "hidden"}}>
                <img src={require("../assets/btn_close_control.png")}/>
            </CloseWrapper>
        )
    })

    const SaveButton = React.memo(function saveButton({ onClick }: {onClick: ()=>void}) {
        return (
            <TransButton onClick={onClick}>
                 <img src={require("../assets/btn_save_control.png")} style={{ width: "32px", height: "32px"}}/>
            </TransButton>
        )
    })

    const DeleteButton = React.memo(function deleteButton({ onClick }: {onClick: ()=>void}) {
        return (
            <TransButton onClick={onClick}>
                 <img src={require("../assets/btn_reset_control.png")} style={{ width: "26px", height: "32px"}}/>
            </TransButton>
        )
    })

    return (
        <div>
            <StickForm>
                { <StickPen toolSelected={props.drawDatas.toolSelected} color={props.drawDatas.sticks[0].color}  onToolChange={props.onToolChange}/>}
                { <StickMarker toolSelected={props.drawDatas.toolSelected} color={props.drawDatas.sticks[1].color} onToolChange={props.onToolChange}/>}
                { <StickEraser toolSelected={props.drawDatas.toolSelected} onToolChange={props.onToolChange}/>}
            </StickForm>
            <ButtonWrapper>
                <RecordButton />
                <CloseButton />
                { SizeButton(props.drawDatas.size, props.onShowSize) }
                { ColorButton(props.drawDatas.color, props.onShowColor) }
                <SaveButton onClick={props.onSaveShow}/>
                <DeleteButton onClick={props.onEndShow}/>
            </ButtonWrapper>
        </div>
    )
}

export default React.memo(BottomTool);