import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { NoteDeleteDialog } from "../write/NoteDialog";
import { NoteAPI } from "../App";
import { Note } from "../server";

const ShowWrapper = styled.div`
  width: 450px;
  height: 775px;
  padding-bottom: 85px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: -webkit-center;
  text-align: center;
  background-color: #fcfcfc;
  overflow: auto;
`;

const LogoWrapper = styled.img`
  margin-top: 244px;
`;

const BlockWrapper = styled.button`
  width: 163px;
  height: 144px;
  margin: 20px 10px;
  border-radius: 20px;
  border: 0px;
  position: relative;

  :after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 161px;
    height: 142px;
    background: #fcfcfc;
    border-radius: 20px;
    transition: all 0.8s;
  }

  :active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s;
  }

  > img {
    width: 161px;
    height: 142px;
    border-radius: 20px;
    object-fit: none;
    object-position: -22px -18px;
    overflow: visible;
    cursor: pointer;
  }
`;
const FooterWrapper = styled.div`
  width: 450px;
  margin-top: 388px;
  height: 85px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const FooterBottomTool = styled.div`
  position: absolute;
  height: 85px;
  bottom: 0px;
  width: 390px;
  background-color: #fefefe;
  border-top: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  padding: 0 30px;
`;

const IconButton = styled.button`
  margin: 0px;
  width: 32px;
  height: 32px;
  margin-right: 42px;
  background: transparent;
  border: 0;

  > img {
    cursor: pointer;
  }
`;

export const NoteView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isDeleteDialog, setDeleteDialog] = useState(false);

  const onShowHome = () => {
    navigate("/");
  };

  const onShowNoteList = () => {
    navigate("/search");
  };

  const onShowDelete = () => {
    setDeleteDialog(true);
  };

  const onDeleteNote = async () => {
    const response = await NoteAPI(Note.deleteNoteResource(location.state.id));
    if (response.status == 200 || response.status == 201) {
      navigate("/search");
    }
  };

  const onCancel = () => {
    setDeleteDialog(false);
  };

  const ShowContent = React.memo(function showContent() {
    return (
      <ShowWrapper>
        <img src={location.state.imageUrl} />
      </ShowWrapper>
    );
  });

  const FooterContent = React.memo(function showContent() {
    return (
      <FooterWrapper>
        <FooterBottomTool>
          <IconButton onClick={onShowHome}>
            <img src={require("../assets/ic_home.png")} />
          </IconButton>
          <IconButton onClick={onShowNoteList}>
            <img src={require("../assets/ic_block_pen.png")} />
          </IconButton>
          <div style={{ flex: 1 }} />
          <IconButton style={{ marginRight: 0 }} onClick={onShowDelete}>
            <img
              style={{ width: "26px", height: "29px" }}
              src={require("../assets/btn_reset_control.png")}
            />
          </IconButton>
        </FooterBottomTool>
      </FooterWrapper>
    );
  });

  return (
    <div>
      <ShowContent />
      <FooterContent />
      {isDeleteDialog && (
        <NoteDeleteDialog
          onClose={onCancel}
          onSuccess={onDeleteNote}
          onCancel={onCancel}
        />
      )}
    </div>
  );
};
