import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { NoteWriteList } from "./NoteWriteList";

const ShowWrapper = styled.div`
    width: 450px;
    height: 775px;
    padding-bottom: 85px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: -webkit-center;
    text-align: center;
    background-color: #FCFCFC;
`

const LogoWrapper = styled.img`
    margin-top: 244px;
`

const BlockWrapper = styled.button`
    width: 163px; 
    height: 144px;
    margin: 20px 10px;
    border-radius: 20px;
    border: 0px;
    position: relative;

    :after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 161px; 
        height: 142px;
        background: #FCFCFC;
        border-radius: 20px;
        transition: all 0.8s
    }

    :active:after {
        padding: 0;
        margin: 0;
        opacity: 1;
        transition: 0s
    }

    > img {
        width: 161px; 
        height: 142px;
        border-radius: 20px;
        object-fit: none; 
        object-position: -22px -18px; 
        overflow: visible;
        cursor: pointer;
    }
`
const FooterWrapper = styled.div`
    width: 450px;
    margin-top: 388px;
    height: 85px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

const FooterBottomTool = styled.div`
    position: absolute;
    height: 85px;
    bottom: 0px;
    width: 390px;
    background-color: #FEFEFE;
    border-top: 1px solid #E0E0E0;
    display: flex;
    align-items: center;
    padding: 0 30px;
`
 
const IconButton = styled.button`
    margin: 0px;
    width: 32px;
    height: 32px;
    margin-right: 42px;
    background: transparent;
    border: 0;

    > img {
        cursor: pointer;
    }
`

export const NoteTemple = () => {
    const [isTemplate, setTemplate] = useState(false);
    const navigate = useNavigate();

    const onShowHome = () => {
        navigate("/");
    }

    const onShowTemplate = (isShow: boolean) => {
        setTemplate(isShow);
    }

    const onShowNoteList = () => {
        navigate("/search");
    }

    const onShowMathNote = () => {
        navigate("/write", { state: {
            imgName: "img_math.png"
        }});
    }

    const onShowEngNote = () => {
        navigate("/write", { state: {
            imgName: "img_eng.png"
        }});
    }

    const ShowContent = React.memo(function showContent(){
        return(
            <ShowWrapper>
                <LogoWrapper src={require("../assets/logo.png")} />
                <div>
                    <BlockWrapper onClick={onShowMathNote}>
                        <img src={require("../assets/block_3.png")}/>
                    </BlockWrapper>
                    <BlockWrapper onClick={onShowEngNote}>
                        <img src={require("../assets/block_4.png")}/>
                    </BlockWrapper>
                </div>
            </ShowWrapper>
        )
    });

    const FooterContent = React.memo(function showContent(){
        return(
            <FooterWrapper>
                <FooterBottomTool>
                    <IconButton onClick={onShowHome}>
                        <img src={require("../assets/ic_home.png")} />
                    </IconButton>
                    <IconButton onClick={() => onShowTemplate(true)}>
                        <img src={require("../assets/ic_pen.png")} />
                    </IconButton>
                    <IconButton onClick={onShowNoteList}>
                        <img src={require("../assets/ic_block_pen.png")} />
                    </IconButton>
                </FooterBottomTool>
            </FooterWrapper>
        )
    });

    return (
        <div>
            <ShowContent />
            { isTemplate && <NoteWriteList showTemplate={onShowTemplate}/> }
            <FooterContent />
        </div>
    )
}