export const DrawAssets = {
    pen: [
        "btn_pen_red.png",
        "btn_pen_orange.png",
        "btn_pen_yellow.png",
        "btn_pen_green.png",
        "btn_pen_blue.png",
        "btn_pen_black.png",
    ],
    marker: [
        "btn_marker_red.png",
        "btn_marker_orange.png",
        "btn_marker_yellow.png",
        "btn_marker_green.png",
        "btn_marker_blue.png",
        "btn_marker_black.png",
    ],
    eraser: "btn_eraser.png",
    size: [
        {
            default: "btn_weight_size_one.png",
            selected: "btn_weight_size_one_selected.png"
        },
        {
            default: "btn_weight_size_two.png",
            selected: "btn_weight_size_two_selected.png"
        },
        {
            default: "btn_weight_size_three.png",
            selected: "btn_weight_size_three_selected.png"
        },
        {
            default: "btn_weight_size_four.png",
            selected: "btn_weight_size_four_selected.png"
        }, 
        {
            default: "btn_weight_size_five.png",
            selected: "btn_weight_size_five_selected.png"
        },
        {
            default: "btn_weight_size_six.png",
            selected: "btn_weight_size_six_selected.png"
        }
    ],
    color: [
        {
            default: "btn_color_one.png",
            selected: "btn_color_one_selected.png"
        },
        {
            default: "btn_color_two.png",
            selected: "btn_color_two_selected.png"
        },
        {
            default: "btn_color_three.png",
            selected: "btn_color_three_selected.png"
        },
        {
            default: "btn_color_four.png",
            selected: "btn_color_four_selected.png"
        }, 
        {
            default: "btn_color_five.png",
            selected: "btn_color_five_selected.png"
        },
        {
            default: "btn_color_six.png",
            selected: "btn_color_six_selected.png"
        }
    ],
    templateNote: [
        [
            "temple_white.png",
            "temple_black.png",
        ],
        [
            "temple_line_1.png",
            "temple_line_2.png",
            "temple_line_3.png",
        ],
        [
            "temple_pastel_1.png",
            "temple_pastel_2.png",
            "temple_pastel_3.png",
            "temple_pastel_4.png",
        ],
        [
            "temple_grid_1.png",
            "temple_grid_1_1.png"
        ],
        [
            "temple_color_1.png",
            "temple_color_2.png",
            "temple_color_3.png",
            "temple_color_4.png",
            "temple_color_5.png",
            "temple_color_6.png",
        ],
        [
            "temple_emty_red.png",
            "temple_emty_yel.png",
            "temple_emty_gre.png",
            "temple_emty_pp.png",
            "temple_emty_blu.png",
            "temple_emty_bro.png"
        ],
        [
            "temple_postit_1.png",
            "temple_postit_2.png",
            "temple_postit_3.png",
            "temple_postit_4.png"
        ]
    ]
}