import React from "react";
import styled from "styled-components";

const TemplateWrapper = styled.div`
    width: 450px;
    height: 860px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: -webkit-center;
    text-align: center; 
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.3); 
`

const ContentWrapper = styled.div`
    border-radius: 20px;
    left: 50%;
    top: 50%;
    width: 380px;
    height: 208px;
    background-color: white;
`

const CloseWrapper = styled.button`
    position: absolute;
    right: 36px;
    width: 36px;
    height: 30px;
    background-color: Transparent;
    border: none;       
    cursor:pointer;
    margin-top: 14px;
    margin-right: 8px;
    float: right;
    
    > img {
        width: 14px;
        height: 14px;
    }
`

const TitleWrapper = styled.div`
    margin-top: 38px;
    height: 36px;

    > div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 38px;
    }
`

const FirstButtonWrapper = styled.button`
    border: 0;
    border-radius: 30px;
    margin-top: 14px;
    width: 80%;
    height: 46px;
    background-color: #EA2A2A;
    color: white;
`

const SecondButtonWrapper = styled.button`
    width: 80%;
    height: 46px;
    border: 0;
    border-radius: 30px;
    margin-top: 4px;
    background-color: #EAEFF8;
    color: #333333;
`

const CloseButton = React.memo(function closeButton({ onCancel }
    : { onCancel: ()=>void }) {
    return (
        <CloseWrapper onClick={onCancel}>
            <img src={require("../assets/btn_close_control.png")}/>
        </CloseWrapper>
    )
})

export const NoteSaveDialog = ({ onSuccess, onCancel } :
    {
        onSuccess: () => void,
        onCancel: () => void
    }) => {
    return (
        <TemplateWrapper>
            <ContentWrapper>
                <CloseButton onCancel={onCancel}/>
                <TitleWrapper>
                    <div>
                        <h3>
                            작성한 노트를 저장할까요?
                        </h3>
                    </div>
                </TitleWrapper>
                <FirstButtonWrapper onClick={onSuccess}><h3>네,저장할게요</h3></FirstButtonWrapper>
                <SecondButtonWrapper onClick={onCancel}><h3>조금 더 작성할게요</h3></SecondButtonWrapper>
            </ContentWrapper>
        </TemplateWrapper>
    )
}

export const NoteEndDialog = ({ onSuccess, onClose, onCancel } :
    {
        onSuccess: () => void,
        onClose: () => void,
        onCancel: () => void
    }) => {
    return (
        <TemplateWrapper>
            <ContentWrapper>
                <CloseButton onCancel={onCancel}/>
                <TitleWrapper>
                    <h3>
                        저장버튼을 누르지 않으면<br/>내용이 저장되지 않아요. 저장할까요?
                    </h3>
                </TitleWrapper>
                <FirstButtonWrapper onClick={onSuccess}><h3>네,저장할게요</h3></FirstButtonWrapper>
                <SecondButtonWrapper onClick={onClose}><h3>아니요</h3></SecondButtonWrapper>
            </ContentWrapper>
        </TemplateWrapper>
    )
}

export const NoteDeleteDialog = ({ onSuccess, onClose, onCancel }:
    {
        onSuccess: () => void,
        onClose: () => void,
        onCancel: () => void
    }) => {
        return (
            <TemplateWrapper>
                <ContentWrapper>
                    <CloseButton onCancel={onCancel}/>
                    <TitleWrapper>
                        <div>
                            <h3>
                                작성한 노트를 삭제할까요?
                            </h3>
                        </div>
                    </TitleWrapper>
                    <FirstButtonWrapper onClick={onSuccess}><h3>네,삭제할게요</h3></FirstButtonWrapper>
                    <SecondButtonWrapper onClick={onClose}><h3>아니요</h3></SecondButtonWrapper>
                </ContentWrapper>
            </TemplateWrapper>
        )
    }