import React from "react";
import styled from "styled-components"; 
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import 'react-horizontal-scrolling-menu/dist/styles.css';
import useDrag from "./useDrag";
import { TempNoteCard, TempTitleCard } from "./Card";
import { DrawAssets } from "../assets";
import { useNavigate } from "react-router-dom";

const TemplateWrapper = styled.div`
    width: 450px;
    height: 860px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: -webkit-center;
    text-align: center; 
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.3); 
`

const ContentWrapper = styled.div`
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 100%;    
    flex: 1;
    margin-top: 75%;
    margin-bottom: 86px;
    background-color: white;
`

const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 60px;
    box-shadow: 0px 5px 5px #0000000D;
`
const TitleIcon = styled.img`
    margin: 0px;
    margin-left: 24px;
    width: 33px;
    height: 27px;
`
const TitleText = styled.h2`
    flex: 1;
    text-align: left;
    margin-left: 8px;
`

const TitleCloseBtn = styled.button`
    width: 30px;
    height: 24px;
    margin-right: 24px;
    background-color: transparent;
    border: 0;
    position: relative;
    border-radius: 4px;

    :hover {
        background-color: #E0E0E0;
    }
`

const TitleCloseIcon = styled.img`
    width: 24px;
    height: 13px;
`

const TempTitleText = styled.h3`
    margin-top: 24px;
    margin-left: 24px;
    text-align: left;
`

const TempNameWrapper = styled.div`
    margin-left: 16px;
    padding-bottom: 8px;

    .react-horizontal-scrolling-menu--inner-wrapper {
        width: 100%;
    }

    .react-horizontal-scrolling-menu--item  {
        margin: 0;
    }

    .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
        display: none;
      }
      
    .react-horizontal-scrolling-menu--scroll-container {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }      
    .react-horizontal-scrolling-menu--separator {
        display: contents;
    }
`

const WriteButtonWrapper = styled.button`
    width: 200px;
    height: 32px;
    background-color: #F64C4C;
    border: 0;
    border-radius: 20px;
    color: white;
    font-size: 14px;
    display: flex;
    align-items:center;
    justify-content: center;

    > img {
        margin: 0 8px 0 0px;
    }
`

const titleArray: string[] = ["베이직 노트(2)", "옥스포드 노트(3)", "컬러 노트(4)", "격자무늬 노트(2)", "과목별 격자 노트(6)", "과목별 파스텔노트(6)", "포스트잇 노트(4)"];

type NoteWriteProps = {
    showTemplate: (isShow: boolean) => void 
}

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

 
export const NoteWriteList = (props: NoteWriteProps) => {
    const { dragStart, dragStop, dragMove, dragging } = useDrag();
    const [selected, setSelected] = React.useState<number>(0);
    const [noteSelected, setNoteSelected] = React.useState<number>(0);
    const navigate = useNavigate();

    const handleTitleDrag = ({ scrollContainer }: scrollVisibilityApiType) => (
        ev: React.MouseEvent
      ) =>
        dragMove(ev, (posDiff) => {
          if (scrollContainer.current) {
            scrollContainer.current.scrollLeft += posDiff;
          }
    });
    
    const handleTitleItemClick = (itemIndex: number) => () => {
        if (dragging) {
          return false;
        }
        setSelected(selected !== itemIndex ? itemIndex : selected);
        setNoteSelected(0);
    };

    const handleNoteItemClick = (itemIndex: number) => () => {
        if (dragging) {
          return false;
        }
        setNoteSelected(noteSelected !== itemIndex ? itemIndex : noteSelected);
    };

    const onWriteTempleNote = () => {
        const noteName = DrawAssets.templateNote[selected][noteSelected];
        navigate("/write", { state: {
            imgName: noteName
        }});
    }

    const ViewTitle = React.memo(function title() {
        return (
            <TitleWrapper>
                    <TitleIcon src={require("../assets/ic_template_title.png")}/>
                    <TitleText>노트</TitleText>
                    <TitleCloseBtn onClick={() => props.showTemplate(false)}>
                        <TitleCloseIcon src={require("../assets/ic_arrow_down.png")}/>
                    </TitleCloseBtn>
            </TitleWrapper>
        )
    });

    const ContentTitle = React.memo(function contentTitle() {
        return(
            <TempTitleText> 
                    빈노트
            </TempTitleText>
        )
    })

    const WriteButton = React.memo(function writeButton() {
        return (
            <WriteButtonWrapper onClick={onWriteTempleNote}>
                <img src={require("../assets/ic_pencil_button.png")} style={{ height: "16px", width: "16px"}}/> 필기하기
            </WriteButtonWrapper>
        )
    })

    return (
        <TemplateWrapper>
            <ContentWrapper>
                <ViewTitle />
                <ContentTitle/>
                <TempNameWrapper onMouseLeave={dragStop} style={{marginTop: "14px"}}>
                    <ScrollMenu
                        onWheel={onWheel}
                        onMouseDown={() => dragStart}
                        onMouseUp={() => dragStop}
                        onMouseMove={handleTitleDrag}>
                        {
                            titleArray.map((el, index) => <TempTitleCard
                                key={index} 
                                title={el}
                                selected={index == selected} 
                                onClick={handleTitleItemClick(index)}/>)
                        }
                    </ScrollMenu>
                </TempNameWrapper>
                <TempNameWrapper>
                    <ScrollMenu 
                        onWheel={onWheel}
                        onMouseDown={() => dragStart}
                        onMouseUp={() => dragStop}>
                        {
                            DrawAssets.templateNote[selected].map((el, index) => 
                                <TempNoteCard 
                                    key={index}
                                    cardName={el}
                                    selected={index == noteSelected }
                                    onClick={handleNoteItemClick(index)}/>)
                        }
                    </ScrollMenu>
                </TempNameWrapper>
                <WriteButton />
            </ContentWrapper>
        </TemplateWrapper>
    )
}

function onWheel(apiObj: scrollVisibilityApiType, ev: React.WheelEvent): void {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;
  
    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }
  
    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
}